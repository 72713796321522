@import "../../../styles/vars";
@import "../../../styles/media";

$aside-width: 10rem;

main.main-layout {
    display: flex;
    position: relative;
    aside {
        display: flex;
        flex-direction: column;
        width: $aside-width;
        height: calc(100vh - #{$header-height});
        position: sticky;
        top:$header-height;
        > div {
            position: relative;
            top: 10%;
            letter-spacing: 1px;
            margin-right: 10px;
            white-space: nowrap;
            padding: 8px 5px;
            a[class="active"] {
                color: $bg-clr;
                padding: 0.6rem 0rem;
                position: relative;
                font-weight: 700;
                &::before {
                    content: '';
                    width: 100%;
                    height: 3px;
                    position: absolute;
                    background: $bg-clr;
                    bottom: 0;
                }
            }
    
            @include sm {
                padding: 0;
                text-align: right;
                margin: 5px 0;
                a {
                    padding: 0.2rem;
                    &[class="active"] {
                        // border-right: 3px solid $bg-clr;
                        padding-right: 5px;
                        &::before {
                            content: '';
                            width: 3px;
                            height: 60%;
                            position: absolute;
                            background: #3D5060;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
        
        display: none;
        @include sm {
            display: flex;
            flex-direction: column;

        }
    }

    .page-links {
        // border: 1px solid red;
    }

    .content {
        flex-grow: 1;
        width: calc(100vw - #{$aside-width});
        .content-header {
            // position: sticky;
            // top: 0;// -257px;


            &:first-child {
                // position: sticky;
                // top: $header-height;
                &.isSticky {
                    position: sticky;
                    top: $header-height;
                    line-height: 3;
                }

                .main-layout-page-title {
                        // background: #1e2c3891;
                    h1 {
                        color: red;
                        padding: $con-padding;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        margin:0;
                        // line-height: 3;
                    }
                }
                .page-links.horizontal {
                    background: #1e2c38e8;
                    padding: $con-padding;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    display: flex;
                    min-height: 50px;
                    flex-wrap: wrap;
                    gap: 15px;
                    align-items: center;
                    > * {// all links
                        padding: 0.5rem 0rem;
                        a {
                            background: red;
                            padding: 0.5rem 0.5rem;
                            color: white;
                        }
                    }
                    @include sm {
                        display: none;
                    }
                }
            }
            .content-header-menu-and-header {
                // height: 40px;
                // background-color: red;
                // position: fixed;
                // // display: none;
                // z-index: 100;
                // // top: 0;
                // left: 0;
                // width: 100%;
                // text-align: center;
                // color: #fff;
                position: relative;
                .header-watcher {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    display: none;
                    // background: green;
                }
            }

        }

        .content-header + * {
            padding: $con-padding;
            padding-top: 1rem;
            padding-bottom: 2rem;
        }
        
    }
}
