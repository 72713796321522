.page-links {
    display: flex;
    

    &.vertical {
        flex-direction: column;
        width: 100%;
        line-height: 2;
    }
    &.horizontal {

    }
}